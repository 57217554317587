import 'whatwg-fetch'
import {ErrorMessage} from './ErrorMessage'

const API = {
  getTeamsForUser: (email) => {
    return new Promise((resolve, reject) => {
      fetch('/api/teamsForUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email
        })
      }).then((response) => { 
        return response.json()
      }).then((response) => {
        if (response.error) {
          if (response.reason === 'invalid-email') {
            reject(new Error(ErrorMessage.invalidFormat))
          }
        } else {
          if (response.teams.length > 0) {
            response.teams.sort((a, b) => (a.domain > b.domain) ? 1 : -1)
            resolve(response.teams)
          } else {
            reject(new Error(ErrorMessage.noTeamsFound))
          }
        }
      }).catch((e) => {
        reject(new Error(ErrorMessage.unknown))
      })
    })
  }
}

export default API