import React, { Component } from "react";
import LoginProcess from "./LoginProcess";
import { ContextProvider } from "../Context";
import packageInfo from '../../package.json';
import buildId from '../buildId.json';
import { Referral } from "./Referral";

export default class Root extends Component {
  render() {
    return (
      <ContextProvider>
        <section id="login-container">
          <div className="loginView">
            <div className="loginView__loginArea">
              <a className="loginView__parim-logo" href="https://parim.co">
                <img
                  src="https://d3c5t20k88187g.cloudfront.net/productpage.parim.co.uk/system/parim-logo.svg"
                  height="52"
                  alt="PARiM"
                />
              </a>
              <div className="loginView__loginArea__wp">
                <div className="loginView__loginArea__top"></div>
                <div className="loginView__loginArea__fm">
                  <LoginProcess />
                </div>
              </div>
            </div>
            <div className="loginView__sideArea">
            <div className="loginView__sideArea__wp">
                <Referral />
                <div className="loginView__sideArea__vr">
                  <a
                    href="https://parim.co"
                    target="blank"
                    title="Workforce Management Software"
                  >
                    version {packageInfo.version} (build: {buildId.buildId})
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ContextProvider>
    );
  }
}
