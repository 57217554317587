import React, { Component } from 'react';
import {Context, EMAIL_REGEXP, LoginStep} from '../../Context';
import backIcon from './back.svg'
import forwardIcon from './forward.svg'
import nextIcon from './next.svg';
import questionMarkIcon from './questionmark.svg';
import {getBoldString} from "../../ErrorMessage";

export default class LoginProcess extends Component {
  componentDidMount(){
    this.emailInput && this.emailInput.focus();
  }

  render() {
    let context = this.context

    return (
      <div id="login-form" className="loginForm">
        <div id="login-wrapper">
          {context.loginStep === LoginStep.TeamSelection &&
            <img className="Back" src={backIcon} alt={context.i18n('Back')} title={context.i18n('Back')} onClick={ () => { context.actions.onBack() }} />
          }
          <div id="WelcomeBack">{context.i18n('Welcome back')}</div>
            <div className="help" onClick={() => context.actions.onHelpClick()}>
                {context.i18n('Help')}
                <img src={questionMarkIcon} alt={context.i18n('Help')} title={context.i18n('Help')} />
            </div>
        </div>

        {context.loginStep === LoginStep.EmailEntry &&
          <React.Fragment>
            <div className={'row email-row cf ' + (context.isEmailInvalid ? 'error' : '')}>
              <label htmlFor="LoginForm_email">{context.i18n('Enter your email')}</label>
              <input
                id="LoginForm_email"
                ref={(input) => {
                  this.emailInput = input;
                }}
                name="email"
                type="text" placeholder="example@email.com"
                className={context.email.length === 0 ? '' :  EMAIL_REGEXP.test(context.email) ? '' : 'error'}
                value={context.email}
                readOnly={context.isLoading}
                onChange={() => {
                  context.actions.onEmailChanged(this.emailInput.value)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") context.actions.onEmailEntered()
                }}
              />
              <div id="messages">
                <div className="page-alert">
                  <div className="errorSummary">
                    <ul>
                      <li>{getBoldString(context.i18n(context.errorMessage))}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row submit-row cf">
              <button
                type="submit"
                disabled={context.email.length === 0 || context.isEmailInvalid}
                onClick={() => {
                  context.actions.onEmailEntered()
                }}
                className={context.isLoading ? "loading" : "submit"}
              >
                {context.i18n('Continue')}
                <img src={nextIcon} alt={context.i18n('Continue')} title={context.i18n('Continue')}/>
              </button>
            </div>
          </React.Fragment>
        }

        {context.loginStep === LoginStep.TeamSelection &&
          <React.Fragment>
            <div className="row cf" id="information">
              <div id="username">{context.email}</div>
              <div className="help" onClick={() => context.actions.onBack()}>
                {context.i18n('Change')}
              </div>
            </div>
            <div id="messages">
              <div className="page-alert">
                <div className="errorSummary">
                  <ul>
                    <li>{context.i18n(context.errorMessage)}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row cf">
              <label>{context.i18n('Choose your team')}</label>
            </div>
            <div className="TeamList">
              {context.teams.map((team, index) => {
                let teamName = team.domain + (team.name != null && team.name.length > 0 ? (" – " + team.name) : "")
                let teamAddress = team.domain;
                return (
                  <div key={index} className="Team" onClick={() => {
                    context.actions.onTeamSelected(team.domain)
                  }}>
                    <div className="left-side">
                      <div className="TeamName">{teamName}</div>
                      <div className="TeamAddress">{teamAddress}<span>.parim.co</span></div>
                    </div>
                    <div className="right-side">
                      <img className="Go" src={forwardIcon} alt=""/>
                    </div>

                  </div>
                )
              })}
            </div>
          </React.Fragment>
        }
      </div>
    );
  }
}
LoginProcess.contextType = Context