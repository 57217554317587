import React, { Component } from 'react'
import api from './API'
import { ErrorMessage} from './ErrorMessage'
import languages from './languages'

export const Context = React.createContext()

export const LoginStep = {
  EmailEntry: "EmailEntry",
  TeamSelection: "TeamSelection"
}

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class ContextProvider extends Component {
  state = {
    currentLanguageCode: "en",
    i18n: languages["en"].i18n,
    languages: languages,
    loginStep: LoginStep.EmailEntry,
    email: "",
    isEmailInvalid: false,
    errorMessage: "",
    teams: [],
    isLoading: false,
    actions: {
      onLanguageChanged: (code) => {
        this.setState({
          currentLanguageCode: code,
          i18n: languages[code].i18n
        })
      },
      onEmailChanged: (email) => {
        this.setState({
          email: email.toLowerCase()
        });

        if (EMAIL_REGEXP.test(email)) {
          this.setState({
            isEmailInvalid: false,
            errorMessage: null
          });
        } else {
          this.setState({
            isEmailInvalid: true,
            errorMessage: ErrorMessage.invalidFormat
          });
        }

      },
      onEmailEntered: () => {
        if (this.state.email.match("(.+)@(.+)") == null) {
          this.setState({
            isEmailInvalid: true,
            errorMessage: ErrorMessage.invalidFormat
          })
        } else {
          this.setState({
            isLoading: true,
            isEmailInvalid: false,
            errorMessage: ""
          })
          api
            .getTeamsForUser(this.state.email)
            .then(
              (teams) => {
                this.setState({
                  loginStep: LoginStep.TeamSelection,
                  isLoading: false,
                  teams: teams
                })
              },
              (error) => {
                this.setState({
                  isLoading: false,
                  errorMessage: error.message
                })
              }
            )
        }
      },
      onBack: () => {
        this.setState({
          loginStep: LoginStep.EmailEntry,
          teams: []
        })
      },
      onTeamSelected: (team) => {
        document.location = `https://${team}.parim.co/site/login?ref=${encodeURIComponent('https://login.parim.co')}&email=${encodeURIComponent(this.state.email)}`
      },
      onHelpClick: () => {
        window.open('https://support.parim.co/en/articles/6611477-logging-into-parim', '_blank')
      }
    }
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const ContextConsumer = Context.Consumer