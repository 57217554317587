import React from 'react'


export const ErrorMessage = {
  invalidEmail: 'Please enter a valid email',
  unknown: 'An unknown error has occurred',
  noTeamsFound: 'No teams found for this email',
  invalidFormat: 'Incorrect formatting. Please use the following formatting: **example@mail.com**'
}

export const getBoldString = (text) => {
  if (!text) return;
  const parts = text.split('**');
  return parts.map((part, index) => (
    index % 2 === 1
      ?
      <span style={{fontWeight: 'bold', color: 'inherit', fontSize: 'inherit'}} key={ index }>{ part }</span>
      : part
  ));
};